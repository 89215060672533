<template>
  <Scrollbar>
    <Table :auto-loading="true" :options="handleOptions" :loadData="handleLoadData" title="菜单管理" ref="table">
      <template #search>
        <el-form :inline="true" :model="formSearch" class="demo-form-inline">
          <el-form-item label="菜单名称">
            <el-input v-model="formSearch.title" placeholder="菜单名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleRefresh">查询</el-button>
          </el-form-item>
        </el-form>
      </template>
      <template #action>
        <div>
          <el-button type="primary" icon="el-icon-plus" @click="handleCreate">添加</el-button>
        </div>
      </template>
      <template #table-item-block_id="{row}">
        {{ row['block']?row['block'].title : '-' }}
      </template>
      <template #table-item-icon="{row}">
        <el-image style="width: 32px;" :src="row.icon" :preview-src-list="[row.icon]"></el-image>
      </template>
      <template #handle="{index,row}">
        <el-button type="text" icon="el-icon-edit" @click="handleUpdate(row)">修改</el-button>
        <el-button type="text" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
      </template>
    </Table>
  </Scrollbar>
</template>
<script type="text/javascript">
import edit from './component/edit.vue'
export default {
  name: "medal",
  components: {
    edit
  },
  data() {
    return {
      formSearch: {

      },
      tableData: []
    };
  },
  mounted() {

  },
  methods: {
    handleOptions(table) {
      //获取分页数据结构
      this.$cloud.get("header/menus/menus").then(res => {
        let {header, search} = res;
        table.setHeader(header);
        table.setSearch(search);
      }).catch(err => {
        table.errMsg(err)
      })
    },

    handleLoadData(table, {size, page}, search = {}) {
      //获取分页数据结构
      this.$cloud.get("menu/list", {size, page,...this.formSearch}).then(res => {
        table.setData(res);
      }).catch(err => {
        table.errMsg(err)
      })
    },

    handleCreate(){
      this.$cloud.dialog({
        title: '添加菜单',
        data: {},
        component: edit,
        success: '添加成功!',
        warning: '添加失败!',
        refresh: this.handleRefresh
      })
    },
    handleUpdate(item){
      this.$cloud.dialog({
        title: '更新菜单',
        data: item,
        component: edit,
        success: '更新成功!',
        warning: '更新失败!',
        refresh: this.handleRefresh
      })
    },

    handleDelete(item){
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$cloud.post("menu/remove", {id: item.id}).then(() => {
          this.$message.success('删除成功！')
          this.handleRefresh()
        })
      })
    },

    handleRefresh(){
      this.$nextTick(() => {
        this.$refs['table'].resetPage()
        this.$refs['table'].handleLoadData()
      })
    }
  },
};
</script>
<style lang="scss"></style>
