var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Scrollbar',[_c('Table',{ref:"table",attrs:{"auto-loading":true,"options":_vm.handleOptions,"loadData":_vm.handleLoadData,"title":"菜单管理"},scopedSlots:_vm._u([{key:"search",fn:function(){return [_c('el-form',{staticClass:"demo-form-inline",attrs:{"inline":true,"model":_vm.formSearch}},[_c('el-form-item',{attrs:{"label":"菜单名称"}},[_c('el-input',{attrs:{"placeholder":"菜单名称"},model:{value:(_vm.formSearch.title),callback:function ($$v) {_vm.$set(_vm.formSearch, "title", $$v)},expression:"formSearch.title"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleRefresh}},[_vm._v("查询")])],1)],1)]},proxy:true},{key:"action",fn:function(){return [_c('div',[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-plus"},on:{"click":_vm.handleCreate}},[_vm._v("添加")])],1)]},proxy:true},{key:"table-item-block_id",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row['block']?row['block'].title : '-')+" ")]}},{key:"table-item-icon",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"32px"},attrs:{"src":row.icon,"preview-src-list":[row.icon]}})]}},{key:"handle",fn:function(ref){
var index = ref.index;
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text","icon":"el-icon-edit"},on:{"click":function($event){return _vm.handleUpdate(row)}}},[_vm._v("修改")]),_c('el-button',{attrs:{"type":"text","icon":"el-icon-delete"},on:{"click":function($event){return _vm.handleDelete(row)}}},[_vm._v("删除")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }