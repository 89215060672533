<!--
 * ======================================
 * 说明： 菜单管理   新增  修改
 * 作者： Silence
 * 文件： edit.vue
 * 日期： 2023/8/21 0:49
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <el-form :model="editForm" :rules="rules" ref="editForm" label-width="100px">
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item label="菜单名称" prop="title">
          <el-input v-model="editForm.title"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item label="关联模块" prop="blockId">
          <el-select class="w-full" v-model="editForm.blockId" placeholder="请选择" clearable @change="$forceUpdate()">
            <el-option v-for="item in blockList" :key="'block'+item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item label="菜单排序">
          <el-input-number class="w-full" v-model="editForm.index"></el-input-number>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item label="菜单地址" prop="path">
          <el-input :rows="3" type="textarea" v-model="editForm.path"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
<!--    <el-row :gutter="20">-->
<!--      <el-col :span="24">-->
<!--        <el-form-item label="菜单图标" prop="icon">-->
<!--          <el-upload-->
<!--            style="overflow: hidden"-->
<!--            :limit="1"-->
<!--            action="#"-->
<!--            list-type="picture-card"-->
<!--            :show-file-list="false"-->
<!--            :http-request="onUploadPicture"-->
<!--          >-->
<!--            <img v-if="editForm.icon" :src="editForm.icon" class="avatar w-full" />-->
<!--            <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
<!--          </el-upload>-->
<!--        </el-form-item>-->
<!--      </el-col>-->
<!--    </el-row>-->
  </el-form>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {
      value:Object,
    },
    data() {
      return {
        blockList:[],
        editForm:{
          title:null,
          blockId:null,
          path:null,
          icon:null,
        },
        rules:{
          title:[{ required: true, message: '请输入菜单名称', trigger: 'blur' }],
          path:[{ required: true, message: '请输入菜单地址', trigger: 'blur' }],
        }
      }
    },
    mounted() {
      this.getBlockList();
      if(this.value.id){
        this.handleLoadForm(this.value.id)
      }else{
        this.editForm = {
          title:null,
          blockId:null,
          path:null,
          icon:null,
        }
      }
    },
    methods: {
      onUploadPicture(file) {
        let params = {
          file: file.file,
        };
        this.loading = true;
        this.$cloud
          .upload("files/upload", params)
          .then((res) => {
            this.loading = false;
            this.editForm.icon = res.url;
          })
          .catch((err) => {
            this.loading = false;
            // this.$message.error(err);
          });
      },
      // 获取模块
      getBlockList(){
        this.$cloud.get('block/list',{
          page:1,
          size: 99999
        }).then(res=>{
          this.blockList = res
        })
      },
      // 加载表单数据
      handleLoadForm(id){
        this.$cloud.get('menu/detail',{id}).then(res=>{
          this.editForm = res
          this.editForm.blockId = res.block_id || ''
        })
      },
      // 提交数据
      handleSubmit(){
        return new Promise((resolve,reject)=>{
          this.$refs['editForm'].validate((valid)=>{
            if(valid){
              let api = this.editForm.id ? 'menu/edit' : 'menu/create'
              this.$cloud.post(api,this.editForm).then(()=>{
                resolve(true)
              }).catch(()=>{
                reject(false)
              })
            }else{
              reject(false)
            }
          })
        })
      }
    },
  }
</script>
<style lang="scss" scoped></style>
